import { useEffect, useState } from "react";
import Question from "../Classes/Question";
import './PortraitWork.css';
import './buttons.css';
import FullList from "./FullList";
import IContainer from "./Container";
import QuestionForm from "./Question";
import TipForm from "./TipPanel";
import ResultForm from "./Results";
import { isMobile } from "react-device-detect";

//Форма для работы
type WorkProps = {
 /**Массив вопросов */
 questions: Question[];
 /**Флаг режима */
 isExaming: boolean;
 /**Данные пользователя */
 user:User;
}

const PortraitWork = ({ questions, isExaming, user }: WorkProps) => {
 //Завершение работы
 const [isOver, setIsOver] = useState<boolean>(false);

 //Отображение подсказки
 const [showTip, setShowTip] = useState<boolean>(false);

 //Выбор вопроса
 const [isMenuVisible, setVisibleMenu] = useState<boolean>(false);

 //Выбранны ответ на вопрос
 const [answerIndex, setAnswerIndex] = useState<number>(-1);

 //Заполнение данных
 const [questionIndex, setQuestionIndex] = useState<number>(0);
 const [completePercent, setCompletePercent] = useState<number>(0.0);
 const [containers, _] = useState<IContainer[]>(questions.map((q, indexOf) => {
  return {
   index: indexOf, isCorrect: false, selectedAnswer: -1, IsComplete() {
    return this.selectedAnswer >= 0;
   },
  }
 }));

 //Возврат индекса следующей вопроса
 const GetNextQuestion = (): number => {
  let index = -1;

  for (let i = 0; i < containers.length; i++) {
   if (containers[i].IsComplete() == false) {
    index = i;
    break;
   }
  }

  return index;
 }

 //Переход на следующий вопрос
 const NextQuestion = () => {
  const nextIndex = GetNextQuestion();
  if (nextIndex >= 0) setQuestionIndex(nextIndex);
  else {
   //Завершение работы
   setIsOver(true);
   Reset();
  }
 };

 //Сброс данных
 const Reset = () => {
  //Сброс данных при смене индекса вопроса
  setAnswerIndex(-1);
  setVisibleMenu(false);
  const nextIndex = GetNextQuestion();
  setCompletePercent(((nextIndex >= 0) ? nextIndex : questions.length) / questions.length);
 }

 //Обработка данных при смене индекса вопроса
 useEffect(() => {
  //Сброс данных для обновления
  Reset();
 }, [questionIndex]);

 //Обработка выбора ответа
 const AnswerSelectedHandle = (index: number) => {
  setAnswerIndex(index);
 }

 //Обработка подверждения ответа
 const OnSubmitQuestion = () => {
  let container: IContainer = containers[questionIndex];
  if (container.IsComplete()) {
   //Если вопрос завершен то просто переход к следующему
   NextQuestion();
  } else {
   //Иначе обновить данные конейнера
   const isCorrect = questions[questionIndex].GetAnswers()[answerIndex].isCorrect;
   container = {
    index: questionIndex, isCorrect: isCorrect, selectedAnswer: answerIndex, IsComplete() {
     return this.selectedAnswer >= 0;
    },
   };
   containers[questionIndex] = container;

   //Сброс данных для обновления
   if (isCorrect == false && isExaming == false) Reset();
   else NextQuestion();
  }
 }

 return (
  <div>
   {
    questions.length > 0 ? (
     <div>
      {isOver && isExaming ? (
       <ResultForm user={user} questions={questions} containers={containers} maxErrors={2}/>
      ) : (
       <div className="main-container" style={isMobile ? {} : {height:'100%'}}>
        <div className="line-container">
         <div className="index-line-back" style={{ width: `${100.0 - completePercent * 100.0}%`, display: `${(completePercent > 0.99) ? 'none' : 'block'}` }}></div>
         <div className="index-line-front" style={{ width: `${completePercent * 100.0}%`, display: `${(completePercent < 0.01) ? 'none' : 'block'}` }}></div>
        </div>

        <QuestionForm key={questionIndex} question={questions[questionIndex]} container={containers[questionIndex]} OnAnswerSelected={(index: number) => AnswerSelectedHandle(index)} />
        <div className="buttons-container" style={showTip ? { visibility: 'hidden' } : {}}>
         <div className="buttons-holder">
          <button style={isExaming ? { visibility: 'hidden' } : {}} className="button-round" disabled={questions[questionIndex].tips.length <= 0} onClick={() => { setShowTip(true) }}>ПОДСКАЗКА</button>
          <button className="button-round" disabled={(answerIndex < 0 && containers[questionIndex].IsComplete() == false) || GetNextQuestion() < 0} onClick={OnSubmitQuestion}>
           {((containers[questionIndex].IsComplete()) ? 'ДАЛЬШЕ' : 'ВЫБРАТЬ')}
          </button>
          <button style={isExaming ? { visibility: 'hidden' } : {}} className="button-round" onClick={() => { setVisibleMenu(true) }}>
           ВОПРОС {questionIndex + 1}
          </button>
         </div>
        </div>

        {isMenuVisible && <FullList
         options={questions.map((q, index) => 'Вопрос №' + (index + 1))}
         index={questionIndex}
         OnSelected={(index) => { setQuestionIndex(index); setVisibleMenu(false); }} />}

        {showTip && <TipForm
         tip={questions[questionIndex].tips}
         link={questions[questionIndex].link}
         OnClose={() => { setShowTip(false) }} />}
       </div>
      )}
     </div>
    ) : (null)
   }
  </div>
 )

}

export default PortraitWork;