import { useEffect, useState } from "react";
import { X2jOptions, XMLParser } from "fast-xml-parser";
import Answer from "../Classes/Answer";
import Question from "../Classes/Question";
import Theme from "../Classes/Theme";
import { useMediaQuery } from "react-responsive";
import Menu from "../Components/Menu";
import '../Components/buttons.css';
import TopForm from "../Components/TopPanel";
import Data from "../Classes/Data";
import { isMobile } from "react-device-detect";
import { ThemesContext } from "../Components/ThemesContext";
import LoadForm from "../Components/LoadingForm";
import './main.css';

const Main = () => {
 //Глобальное хранение данных
 const [themes, setThemes] = useState<Theme[]>([]);

 const isPortrait = useMediaQuery({
  query: "(orientation: portrait)"
 });

 //Загрузить или восстановить данные единожды после загрузки главной страницы
 useEffect(() => {
  const userKey: string = 'User';
  const user: User = {
   GroupValue: 0,
   VoltageValue: 0,
   EmployValue: 0,
   ThemeName: 'Все темы',
   workPlaceName: Data.WorkPlaces[0],
   professionName: Data.Professions[0],
   name: '',
   stageYears: 0,
   questionsLimit: 10
  }
  sessionStorage.setItem(userKey, JSON.stringify(user));

  const themesKey: string = 'Themes';
  let collectedThemes: Theme[] = []; 

  if (sessionStorage.getItem(themesKey)) {
   try {
    //Восстановление данных
    const restoredThemes = JSON.parse(sessionStorage.getItem(themesKey) as string);
    if (Array.isArray(restoredThemes)) {
     try {
      for (const copyTheme of restoredThemes) {
       const newTheme: Theme = Theme.FromJson(copyTheme as Theme);
       collectedThemes.push(newTheme);
      }
     } catch (error) {
      console.log('Не удалось прочитать восстановленные данные ' + error);
      collectedThemes = [];
     } 
    }
    
    if (collectedThemes.length > 0) {
     //Установка данных
     setThemes(collectedThemes);

     console.log(`Восстановлены ${collectedThemes.length} тем`);
    }
   } catch (error) {
    console.log('Не удалось восстановить данные ' + error);
   }
  }

  if (collectedThemes.length <= 0) {
   fetch('/Assets/ES.xml').then((res) => res.text()).then((xmlText) => {
    const options: Partial<X2jOptions> = {
     ignoreAttributes: false
    }
    const parser = new XMLParser(options);
    const json = parser.parse(xmlText);

    if ('Themes' in json) {
     try {
      //Выборка элементов по ключам
      const themes = json['Themes']['Theme' as keyof typeof String];
      for (const theme of themes) {
       let employFlag: number = -1;
       let name: string = 'New Theme';
       let index: number = -1;

       //Парсим флаг сотрудника из объекта в строку и дальше в число
       if ('@_EmployFlags' in theme) employFlag = parseInt(theme['@_EmployFlags'] as string);
       //Парсим индекс темы из объекта в строку и дальше в число
       if ('@_Index' in theme) index = parseInt(theme['@_Index'] as string);
       //Парсим название темы из объекта в строку
       if ('Value' in theme) name = theme['Value'] as string;

       if (index >= 0) {
        const newTheme: Theme = new Theme(index, name, employFlag);

        //Ищем вопросы для темы
        if ('Questions' in theme) {
         const questions = theme['Questions']['Question' as keyof typeof String];
         for (const question of questions) {

          employFlag = -1;
          let voltageFlag = -1;
          let groupFlag = -1;
          index = -1;
          name = 'New Question';

          //Парсим флаг сотрудника из объекта в строку и дальше в число
          if ('@_EmployFlags' in question) employFlag = parseInt(question['@_EmployFlags'] as string);
          //Парсим флаг напряжения из объекта в строку и дальше в число
          if ('@_VoltageFlags' in question) voltageFlag = parseInt(question['@_VoltageFlags'] as string);
          //Парсим флаг группы из объекта в строку и дальше в число
          if ('@_GroupFlags' in question) groupFlag = parseInt(question['@_GroupFlags'] as string);
          //Парсим индекс вопроса из объекта в строку и дальше в число
          if ('@_Index' in question) index = parseInt(question['@_Index'] as string);
          //Парсим название вопроса из объекта в строку
          if ('Value' in question) name = question['Value'] as string;

          if (index >= 0) {
           const newQuestion: Question = new Question(index, name, employFlag, voltageFlag, groupFlag);

           //Парсим подсказку вопроса
           if ('Tips' in question) newQuestion.tips = question['Tips'] as string;
           //Парсим ссылку вопроса
           if ('@_Link' in question) newQuestion.link = question['@_Link'] as string;

           //Ищем ответы для вопроса
           if ('Answers' in question) {
            const answers = question['Answers']['Answer' as keyof typeof String];
            for (const answer of answers) {

             index = -1;
             name = 'New Answer';
             let isCorrect: boolean = false;

             //Парсим индекс ответа из объекта в строку и дальше в число
             if ('@_Index' in answer) index = parseInt(answer['@_Index'] as string);
             //Парсим название ответа из объекта в строку
             if ('Value' in answer) name = answer['Value'] as string;
             if ('@_Correct' in answer) isCorrect = answer['@_Correct'] === 'True';

             if (index >= 0) {
              //Добавляем новый ответ в вопрос
              const newAnswer: Answer = new Answer(index, name, isCorrect);
              newQuestion.AddAnswer(newAnswer);
             }

            }
           }

           //Добавляем новый вопрос в тему
           if (newQuestion.Size > 0) newTheme.AddQuestion(newQuestion);
          }

         }
        }

        //Добавляем новую тему в общий массив
        if (newTheme.Size > 0) collectedThemes.push(newTheme);
       }
      }

      if (collectedThemes.length > 0) {
       //Сохранение в session storage
       const data: string = JSON.stringify(collectedThemes);
       sessionStorage.setItem(themesKey, data);

       //Установка данных
       setThemes(collectedThemes);

       console.log(`Загружено и сохранено ${collectedThemes.length} тем`);
      } else throw new Error('Темы не были загружены');
     } catch (error) {
      console.log('Failed to cast from json to String[] ' + error);
     }
    }
   }).catch((error) => {
    alert(error);
   });
  }

 }, []);

 //Режим подготовки
 const [isExaming, setExaming] = useState<boolean>(false);
 const OnExaming = (flag:boolean) => setExaming(flag);

 return (
  themes.length > 0 ? (
   <div className="content-container">
    <TopForm OnExaming={OnExaming}/>
    <ThemesContext.Provider value={themes}>
     <div className="center-panel" style={isPortrait ? {display: 'block'} : {display: 'flex'}}>
      <Menu isExaming={isExaming}/>
     </div>
    </ThemesContext.Provider>
   </div>
  ) : (
   {isMobile} ? (<LoadForm/>) : (null)
  )
 ) 
}

export default Main; 