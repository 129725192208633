import Element from "./Element";
import Question from "./Question";

/**Класс для хранения данных темы */
export default class Theme extends Element {

 /**Значение темы */
 public readonly value: string;
 /**Флаг сотрудника*/
 public readonly employFlag: number;

 private objQuestions: Question[];

 constructor(index: number, value: string, employFlag: number) {
  super(index);
  this.value = value;
  this.employFlag = employFlag;
  this.objQuestions = [];
 }
 /**Метод возвращает весь массив вопросов */
 public GetQuestions(): Question[] {
  return this.objQuestions;
 }
 /**Метод добавляет вопрос */
 public AddQuestion(question: Question): void {
  this.objQuestions.push(question);
 }
 /**Размер массива вопросов */
 get Size() {
  return this.objQuestions.length;
 }
 /**Метод преобразует Json в Theme */
 static FromJson(copy: Theme) : Theme {
  let newTheme: Theme = new Theme(copy.index, copy.value, copy.employFlag);

  copy.objQuestions.forEach(q => {
   const newQuestion: Question = Question.FromJson(q);
   newTheme.objQuestions.push(newQuestion);
  });

  return newTheme;
 }

}