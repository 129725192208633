import './tippanel.css';
import './buttons.css';

/**Данные для формы */
type TipProps = {
 /**Текст подсказки, может быть ссылкой на картинку */
 tip:string;
 /**Короткая ссылка */
 link?:string;
 OnClose?():void;
}

/**Форма для отображения подсказки */
const TipForm = ({tip, link = '', OnClose} : TipProps) => {

 const isImage = tip.endsWith('.jpg');

 return (
  <div className='blocker' onClick={OnClose}>
   <div className="container">
    {isImage ? (
     <div className="image-content">
      <div className="tip-image">
       <img src={'Images/' + tip} alt={((link.length > 0) ? link : tip)} />
      </div>
     </div>
    ) : (
     <div className="tip-text">
      <div className="tip-text__link">
       <p className="link-label">{link}</p>
      </div>
      <div className="tip-text__tip">
       <p>{tip}</p>
      </div>
     </div>
    )}
   </div>
   <div style={{marginTop:'3%'}}>
    <button className='button-round'>ЗАКРЫТЬ</button>
   </div>
  </div>
 )
}

export default TipForm;