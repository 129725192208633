import { RefObject, useRef, useState } from 'react';
import './fulllist.css';

/** Настройки списка*/
type FullListProps = {
 /**Элементы списка */
 options:string[];
 /**Текущий индекс списка, по умолчанию равен 0 */
 index?:number;
 /**Колбэк с выбранным индексом */
 OnSelected(index:number):void;
}

/**
 * Компонент отрисовки полноэкранного списка
 */
const FullList = ({options, index = 0, OnSelected} : FullListProps) => {

 //Переход к выбранному элементу
 const ScrollHandle = (ref:HTMLLIElement) => ref?.scrollIntoView();

 return(
  <div className="blocker">
   <div className="list-container">
    <ul className="list">
     {
     options.map((value, indexOf) => (
      <li ref={index == indexOf ? ScrollHandle : null} 
       key={indexOf} 
       className={'list__element' + (index == indexOf ? ' list__element--selected' : '')} 
       onClick={() => {OnSelected(indexOf)}}>
      {value}</li>
     ))
     }
    </ul>
   </div>
  </div>
 )
}

export default FullList;