import Question from "../Classes/Question";
import Theme from "../Classes/Theme";

declare global {
 interface Array<T> {
  /**Метод перетосовывает элементы массива в случайном порядке */
  shuffle():void;
 }

 interface Number {
  /**
   * Метод возвращает число в заданном диапазоне
   * @param min минималое число
   * @param max максимальное число
   */
  clamp(min:number, max:number):number;
 }
}

Array.prototype.shuffle = function() {
 let currentIndex = this.length, randomIndex;

 while(currentIndex != 0) {
  randomIndex = Math.floor(Math.random() * currentIndex);
  currentIndex -= 1;

  [this[currentIndex], this[randomIndex]] = [this[randomIndex], this[currentIndex]];
 }
}

Number.prototype.clamp = function(min:number, max:number):number {
 return (this as number < min) ? min : (this as number > max) ? max : this as number;
}

/**
 * Метод возвращает случайное число, мин - инклюзивно, макс - эксклюзивно
 */
const Random = (min:number, max:number):number => {
 min = Math.ceil(min);
 max = Math.floor(max);
 return Math.floor(Math.random() * (max - min) + min);
}

/**Пара ключ тема, значение выбранные вопросы*/
type ThemePair = {
 /**Ключ - тема */
 key:Theme,
 /**Значение - массив отобранных вопросов */
 value:Question[]
}

/**
 * Метод возвращает массив отсортированных вопросов
 * @param randomLimit Если задано 0 то возвращает максимальное кол-во вопросов
 */
export default function SortQuestions(themes: Theme[], employValue: number, groupValue: number, voltageValue: number, randomLimit: number = 0) : Question[] {
 let collectedQeustions: Question[] = [];

 if (randomLimit == 0) {
  //Вывести все вопросы под фильтром
  themes.map((theme) => {
   let employFlag = theme.employFlag;
   if ((employFlag & employValue) == employValue) {
    const questions: Question[] = theme.GetQuestions();
    for(let i = 0; i < questions.length; i++) {
     const question: Question = questions[i];

     employFlag = question.employFlag;
     const groupFlag = question.groupFlag;
     const voltageFlag = question.voltageFlag;

     if ((employFlag & employValue) == employValue && 
         (groupFlag & groupValue) == groupValue &&
         (voltageFlag & voltageValue) == voltageValue) 
         {
          collectedQeustions.push(question);
         }
    }
   }
  });
 } else if (randomLimit > 0) {
  let collectedThemes:ThemePair[] = [];

  //Отобрать темы с нужными вопросами
  themes.map((theme) => {
   let employFlag = theme.employFlag;
   if ((employFlag & employValue) == employValue) {
    const questions: Question[] = theme.GetQuestions();
    for(let i = 0; i < questions.length; i++) {
     const question: Question = questions[i];

     employFlag = question.employFlag;
     const groupFlag = question.groupFlag;
     const voltageFlag = question.voltageFlag;

     if ((employFlag & employValue) == employValue && 
         (groupFlag & groupValue) == groupValue &&
         (voltageFlag & voltageValue) == voltageValue) 
         {
          const indexOf:number = collectedThemes.map(p => p.key.value).indexOf(theme.value);
          if (indexOf >= 0) {
           //Если есть тема уже в отобранном списке то добавить в ее массив вопрос
           collectedThemes[indexOf].value.push(question);
          } else {
           //Если темы нет то добавить новую
           collectedThemes.push({key:theme, value:[question]});
          }
         }
    }

   }
  });

  //Ограничиваем кол-во вопросов для каждой темы
  const countPerTheme:number = Math.ceil(randomLimit / collectedThemes.length);
  const values = collectedThemes.map((pair, indexOf) => {
   return {
    key: indexOf,
    value: pair.value.length.clamp(0, countPerTheme)
   }
  });

  //Перебрать необходимое колво вопрос для каждой темы
  for (const pair of values) {
   //Индекс в массиве отобранных тем
   const index:number = pair.key;
   //Кол-во вопросов для каждой темы
   let count:number = pair.value;
   
   //Отбор вопрос из каждой темы
   while(count > 0 && randomLimit > 0) {
    //Получаем полный список всех вопросов
    const questions:Question[] = collectedThemes[index].value;
    //Получаем случайный индекс вопроса
    const selectedIndex:number = Random(0, questions.length);
    //Получаем элемент под случайным индексом
    const element:Question = questions[selectedIndex];
    //Добавляем полученный вопрос в итоговый список
    collectedQeustions.push(element);
    //Удаляем найденый элемент из массива вопросов
    questions.splice(selectedIndex, 1);

    count -= 1;
    randomLimit -= 1;
   }
  }

  //Перемешать итоговый массив вопросов
  if (collectedQeustions.length > 0) collectedQeustions.shuffle();
 }

 return collectedQeustions; 
}