/**Перечисление для отображения состояния ответа */
enum AnswerOperation {
 /**Состояние отображения простого текста без точки */
 Idle = 0,
 /**Активное состояние с точко */
 Active = 1,
 /**Состояние выделенного ответа */
 Selected = 2,
 /**Состояние правильного ответа может быть с точкой или без */
 Green = 3,
 /**Состояние выбранного нерпавильного ответа */
 Red = 4
}

export default AnswerOperation;