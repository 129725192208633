import { useEffect, useRef, useState } from 'react';
import './ListForm.css';
import { isMobile } from 'react-device-detect';

type ListProps = {
 //Заголовок
 label: string;
 //Массив названий
 values: string[];
 //Callback с индексом элемента
 OnSelected(index: number): void;
 //Мени выбора на весь экран
 fullMenu?: boolean;
 //Максимальная длина элемента
 maxWidthStyle?:string;
}

const ListForm = ({label, values, OnSelected, fullMenu = false, maxWidthStyle = ''} : ListProps) => {
 //Устаналиваем значение видимости формы, по умолчанию false
 const [isVisible, setVisibility] = useState<boolean>(false);
 //Устанавливаем по умолчанию значение первого выбранного элемента
 const [selectedIndex, setSelectedIndex] = useState<number>(0);

 return(
  <div className='box-thin' style={{maxWidth:maxWidthStyle}}>
  <div style={{display: 'flex'}}><p>{label}</p></div>
  <div style={{display: 'flex'}}>
   <ul style={(isVisible && fullMenu === false) ? {zIndex:'10'} : {zIndex:'auto'}} className='list-options'>
    <a href='#' onClick={() => {
    //Устанавливаем видимость списка
    setVisibility(!isVisible);
    }}>{
     //Устанавливаем название выбранного элемента
     values[selectedIndex]
     }</a>
    {
     (isVisible && fullMenu === false) ? values.map((e, index) => (
      //Если список видимый
      //Перебираем каждый элемент списка названий и его индекс
      //чтобы вернуть его индекс при выборе
      <li key={index} className={'list-element '} onClick={() => {
       //Устанавливаем индекс выбранного элемента
       setSelectedIndex(index);
       //Вызываем callback для выбранного элемента
       OnSelected(index);

       //Закрываем открытый список
       setVisibility(false);
       }}>
       <a className={selectedIndex === index ? 'element-selected' : ''}>{e}</a>
      </li>
     )) : (null)
    }
   </ul>
  </div>

  {
   (isVisible && fullMenu) && (
   <div className='full-list-container'>
    <div className='list-container'>
     <ul className='full-list'>
      {
       values.map((e, index) => (
        <li 
         className={(index == selectedIndex) ? 'full-element-selected' : 'full-element'}   
         key={index} onClick={() => {
         //Устанавливаем индекс выбранного элемента
         setSelectedIndex(index);

         //Вызываем callback для выбранного элемента
         OnSelected(index);

         //Закрываем открытый список
         setVisibility(false);
        }}>
         <a>{e}</a>
        </li>
       ))
      }
     </ul>
    </div>
   </div>
   )
  }

 </div>
 )
}

export default ListForm;