import './menu.css';
import ListForm from './ListForm';
import { useContext, useEffect, useState } from 'react';
import Data from '../Classes/Data';
import { Link } from 'react-router-dom';
import Pages from '../Enums/Pages';
import InputForm from './InputForm';
import { ThemesContext } from './ThemesContext';
import { isMobile } from 'react-device-detect';

//Пропсы для портретной формы отображения
type MenuProps = {
 /**Флаг экзамена или поготовки */
 isExaming: boolean;
}

/**Портретная форма настроек */
const Menu = ({ isExaming }: MenuProps) => {
 const themes = useContext(ThemesContext);

 //Устанавливаем форму пользователя по умолчанию
 const userKey: string = 'User';
 const data: User = JSON.parse(sessionStorage.getItem(userKey) as string) as User;
 const [user, setUser] = useState<User>(data);

 //Данные для изменений
 const [groupValue, setGroupValue] = useState<number>(0);
 const [voltageValue, setVoltageValue] = useState<number>(0);
 const [employValue, setEmployValue] = useState<number>(0);
 const RefreshUser = (data: User) => {
  setUser({
   GroupValue: data.GroupValue,
   VoltageValue: data.VoltageValue,
   EmployValue: data.EmployValue,
   ThemeName: data.ThemeName,
   name: data.name,
   stageYears: data.stageYears,
   professionName: data.professionName,
   workPlaceName: data.workPlaceName,
   questionsLimit: data.questionsLimit,
  })
  //Сохранение данных user
  sessionStorage.setItem(userKey, JSON.stringify(user));
 }

 //УСтановка кол-ва вопросов для экзамена
 useEffect(() => {
  if (isExaming) {
   user.questionsLimit = 10;
   user.workPlaceName = Data.WorkPlaces[0];
   user.professionName = Data.Professions[0];
   setUser(user);
   RefreshUser(user);
  }
 }, [])

 //Обновление ключа
 const [updateIndex, setUpdateIndex] = useState<number>(0);

 //Установка значений для отсортированных тем
 const [themeNames, setThemeNames] = useState<string[]>(['Все темы']);

 //При изменении 
 useEffect(() => {
  //Установка значений по умолчанию
  let newThemeNames: string[] = ['Все темы'];

  themes.map((theme) => {
   //Флаг персонала
   let employFlag = theme.employFlag;
   const employValue = 1 << user.EmployValue;

   let hasQuestions = false;

   if ((employFlag & employValue) == employValue) {
    theme.GetQuestions().map((question) => {
     employFlag = question.employFlag;

     //Флаг напряжения
     const voltageFlag = question.voltageFlag;
     const voltageValue = 1 << user.VoltageValue;
     //Флаг группы
     const groupFlag = question.groupFlag;
     const groupValue = 1 << user.GroupValue;

     if ((employFlag & employValue) == employValue &&
      (voltageFlag & voltageValue) == voltageValue &&
      (groupFlag & groupValue) == groupValue) {
      hasQuestions = true;
     }
    })
   }

   if (hasQuestions) newThemeNames.push(theme.value);
  });

  //Установка тем
  setThemeNames(newThemeNames);
  //Обновление индекса
  setUpdateIndex(updateIndex + 1);

  console.log(`Для заданных параметров нашлось ${themeNames.length} тем`);

  //Сохранение изменений
  user.GroupValue = groupValue;
  user.VoltageValue = voltageValue;
  user.EmployValue = employValue;
  RefreshUser(user);

 }, [groupValue, voltageValue, employValue]);

 return (
  <div className="menu-container">
   <div className='box-container'>
    {
     isExaming && (
      <div className="user-form">
       <div style={isMobile ? {} : { display: 'flex', flexDirection: 'row' }}>
        <ListForm label='Цех, служба' values={Data.WorkPlaces} OnSelected={(index) => { user.workPlaceName = Data.WorkPlaces[index]; RefreshUser(user) }} />
        <ListForm label='Кол-во вопросов' values={['10', '20']} OnSelected={(index) => { index == 0 ? (user.questionsLimit = 10) : (user.questionsLimit = 20); RefreshUser(user) }} />
       </div>
       <ListForm label='Должность' fullMenu={true} values={Data.Professions} OnSelected={(index) => { user.professionName = Data.Professions[index]; RefreshUser(user) }} />
       <div style={{display:'flex', flexDirection: isMobile ? 'column' : 'row', width:'100%'}}>
        <InputForm label='ФИО' focusSelect={true} placeHolder='Введите ФИО' OnValue={(value) => { user.name = value; RefreshUser(user) }} />
        <InputForm label='Стаж' maxWidthStyle={isMobile ? '' : '175px'} placeHolder='Введите число' onlyNumber={true} OnValue={(value) => { user.stageYears = parseInt(value); RefreshUser(user) }} />
       </div>
      </div>
     )
    }

    <div style={{display:'flex', width:'100%', flexDirection:isMobile ? 'column' : 'row'}}>
     <ListForm label='Группа' values={['II', 'III', 'IV', 'V']} maxWidthStyle={isMobile ? '' : '175px'} OnSelected={(index) => setGroupValue(index)} />
     <ListForm label='Напряжение' values={['До 1000В', 'До и Выше 1000В']} OnSelected={(index) => setVoltageValue(index)} />
     <ListForm label='Персонал' values={['Сотрудник', 'Член комиссии']} OnSelected={(index) => setEmployValue(index)} />
    </div>

    <ListForm key={updateIndex} fullMenu={true} label='Темы' values={themeNames} OnSelected={(index) => { user.ThemeName = themeNames[index]; RefreshUser(user) }} />
   </div>

   <div className="buttons-holder" style={{justifyContent:'center', paddingBottom:'2%', flexDirection:'column'}}>
    {isExaming ? (
     <div>
      {user.name.length <= 0 && (<div style={{fontSize:'0.75em', color:'red', paddingBottom:'1em'}}>Введите имя</div>)}
      {user.stageYears <= 0 && (<div style={{fontSize:'0.75em', color:'red', paddingBottom:'1em'}}>Введите стаж</div>)}
      <Link to={Pages.Exam}>
        <button disabled={user.stageYears <= 0 || user.name.length <= 0} style={{width:'150px'}} className='button-round'>НАЧАТЬ</button>
      </Link>
     </div>
    ) : (     
     <Link to={Pages.Testing}>
      <button style={{width:'150px'}} className='button-round'>НАЧАТЬ</button>
     </Link>
     )}
     {isExaming == false ? (<button disabled={true} style={{marginTop:'0.4em', width:'150px'}} className='button-round'>ПРОДОЛЖИТЬ</button>) : (null)}
   </div>

  </div>
 )
}

export default Menu;