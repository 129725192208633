import { useState } from "react";
import Answer from "../Classes/Answer";
import Question from "../Classes/Question";
import IContainer from "./Container";
import './result.css';

/**Данные для формы результатов */
type Props = {
 /**Данные пользователя */
 user:User;
 /**Массив вопросов */
 questions:Question[];
 /**Контейнеры с результатами */
 containers:IContainer[];
 /**Максимальное число ошибок */
 maxErrors?:number;
}

/**Форма результатов */
const ResultForm = ({user, questions, containers, maxErrors = 2} : Props) => {

 const errorsCount = containers.filter(c => c.isCorrect == false).length;
 const passed = errorsCount <= maxErrors;
 //Дата окончания сдачи
 const [date, _] = useState<Date>(new Date());

 return (
  <div className="result-container"> 
   <ul className="result-list">

    <li className="user-line">{passed ? 'СДАНО' : 'НЕ СДАНО'}</li>
    <li className="user-line">{`Допущено ошибок ${errorsCount}`}</li>
    <li className="user-line">{`Дата сдачи ${date.toLocaleString()}`}</li>
    <li className="user-line">{user.name}</li>
    <li className="user-line">{user.professionName}</li>
    <li className="user-line">{user.workPlaceName}</li>
    <li className="user-line">{`Стаж ${user.stageYears}`}</li>

    {containers.map((c, index) => {

     const question:Question = questions[index];
     const answerIndex:number = c.selectedAnswer;
     const answer:Answer = question.GetAnswers()[answerIndex];
     const correction:string = (c.isCorrect) ? 'Правильно' : 'Не правильно';

     return (
      <li key={index} className="result-element">
       <div className="result-element-box">
        <div className="line-question">
         <div className="line-question__label"><p>Вопрос</p></div>
         <div className="line-question__value"><p>{question.value}</p></div>
        </div>
        <div className="line-answer">
         <div className="line-answer__label"><p>Ответ</p></div>
         <div className="line-answer__value"><p>{answer.value}</p></div>
        </div>
        <div className="line-correction"><p>{correction}</p></div>
       </div>
      </li>
     )
    })}
   </ul>
  </div>
 )
}

export default ResultForm;