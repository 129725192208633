import './loadingform.css';

const LoadForm = () => {
 return (
  <div className='loading-container'>
   <p>ЗАГРУЗКА</p>
 </div> 
 )
}

export default LoadForm;