import './answer.css';
import AnswerOperation from "../Enums/AnswerOperation";

/**Настройки элемента ответа */
type AnswerProps = {
 /**Значение ответа */
 value:string;
 /**Состояние ответа */
 state:AnswerOperation;
 /**Правильность ответа */
 isCorrect:boolean;
 /**Событие выбора элемента */
 OnSelected():void;
}

/**Компонент отрисовки варианта ответа */
const Answer = ({value, isCorrect, state, OnSelected} : AnswerProps) => {
 //Выделение элемента
 let elementClass = 'element';
 let dotClass = 'element__dot';

 const isIdle = state == AnswerOperation.Idle;
 const isActive = state == AnswerOperation.Active;
 const isSelected = state == AnswerOperation.Selected;
 const isGreen = state == AnswerOperation.Green;
 const isRed = state == AnswerOperation.Red;

 if (isIdle) {
  dotClass = 'element__dot element__dot--disabled';
 }

 if (isActive) {
  elementClass = 'element';
  dotClass = 'element__dot';
 }

 if (isSelected) {
  elementClass = 'element element--selected';
  dotClass = 'element__dot element__dot--selected';
 }

 if (isGreen) {
  elementClass = 'element element--green';
  dotClass = 'element__dot' + ((isCorrect) ? ' element__dot--green' : ' element__dot--disabled');
 }

 if (isRed) {
  elementClass = 'element element--red';
  dotClass = 'element__dot element__dot--red';
 }
 
 return(
  <li className={elementClass} onClick={() => {if (isActive) OnSelected()}}>
   <div><p className={dotClass}></p></div>
   <p className="element__label">{value}</p>
  </li>
 )
}

export default Answer;