import './inputform.css';

/**Данные формы чисел */
type Props = {
 /**Заголовок */
 label:string;
 /**Формат записи текст или числа */
 onlyNumber?:boolean;
 /**Значение по умолчанию */
 defaultValue?:string;
 /**Заменяющее значение */
 placeHolder?:string;
 /**Выделить при фокусировке */
 focusSelect?:boolean;
 /**Изменение значения */
 OnValue(value:string):void;
 /**Максимальная длина стиля */
 maxWidthStyle?:string;
}

/**Форма числового набора */
const InputForm = ({label, focusSelect = false, maxWidthStyle = '',placeHolder = '', onlyNumber = false, defaultValue = '', OnValue} : Props) => {
 return(
  <div className="input-box" style={{maxWidth:maxWidthStyle}}>
   <p className="input-box__label">{label}</p>
   <div className="input-container">
    <input placeholder={placeHolder} 
     inputMode={onlyNumber ? 'numeric' : 'text'}
     pattern={onlyNumber ? '[0-9]*' : ''}
     defaultValue={defaultValue} className='input-form' 
     type={onlyNumber ? 'number' : 'text'}
     onChange={(e) => OnValue(e.target.value)}
     onFocus={(e) => { if (focusSelect) e.target.select()}}
     />
   </div>
  </div>
 )
}

export default InputForm;