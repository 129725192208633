import { useState } from 'react';
import './toppanel.css';
import { isMobile } from 'react-device-detect';

/**Данные для формы */
type TopFormProps = {
 /**Событие возвращает флаг переключения */
 OnExaming(flag:boolean):void;
}

/**Форма верхушки */
const TopForm = ({OnExaming} : TopFormProps) => {
 //Переключение режимов
 const [examing, setExaming] = useState<boolean>(false);

 return (
  <div className='top-panel' style={isMobile ? {backgroundColor:'transparent'} : {}}>
   <div className='top-panel__buttons'>
    <ul className="switch-list">
     <li style={isMobile ? {width:'60%'} : {width:'15em'}} onClick={() => {setExaming(false); OnExaming(false)}} 
         className={'switch-button' + ((examing) ? '' : ' switch-button--active')}><p>ПОДГОТОВКА</p></li>
     <li style={isMobile ? {width:'40%'} : {width:'10em'}} onClick={() => {setExaming(true); OnExaming(true)}} 
         className={'switch-button' + ((examing) ? ' switch-button--active' : '')}><p>ЭКЗАМЕН</p></li>
    </ul>
   </div>
  </div>
 )
}

export default TopForm;