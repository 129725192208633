/**Класс с данными пользователя */
export default class Data {

 /**Названия цехов или служб */
 static WorkPlaces:string[] = [
  "УАиМО ц.АСУТП", 
  "УАиМО ц.СМ", 
  "УАиМО ЦОиР КТС", 
  "УАиМО ЦПС", 
  "УАиМО САП", 
  "УАиМО"
 ]
 /**Название персонала */
 static Professions:string[] = [
  "Аккумуляторщик V разряда",
   "Наладчик КИПиА IV разряда",
   "Наладчик  КИПиА V разряда",
   "Наладчик  КИПиА VI разряда",
   "Слесарь по КИПиА IV разряда",
   "Слесарь по КИПиА V разряда",
   "Слесарь по КИПиА VI разряда",
   "Электромеханик по РиОМО IV разряда",
   "Электромеханик по РиОМО V разряда",
   "Электромеханик по РиОМО VI разряда",
   "Электромонтер ОПС III разряда",
   "Электромонтер ОПС IV разряда",
   "Электромонтер ОПС V разряда",
   "Электромонтер ОПС VI разряда",
   "Электромонтер по РиОЭ IV разряда",
   "Электромонтер по РиОЭ V разряда",
   "Электромонтер по РиОЭ VI разряда",
   "Инженер-электроник",
   "Инженер-электроник I категории",
   "Инженер-электроник II категории",
   "Ведущий инженер-электроник",
   "Инженер-программист",
   "Инженер-программист I категории",
   "Инженер-программист II категории",
   "Ведущий инженер-программист",
   "Инженер по метрологии",
   "Инженер по метрологии I категории",
   "Инженер по метрологии II категории",
   "Ведущий инженер по метрологии",
   "Инженер",
   "Инженер I категории",
   "Инженер II категории",
   "Ведущий инженер",
   "Техник I категории",
   "Мастер по КАиТ",
   "Начальник цеха",
   "Заместитель начальника цеха",
   "Начальник службы",
   "Заместитель начальника службы",
   "Начальник участка",
   "Заместитель начальника участка",
   "Начальник лаборатории"
 ]

}
