import Element from "./Element";
import Answer from "./Answer";

/**Класс для хранения данных вопроса */
export default class Question extends Element {
  /**Значение вопроса */
  public readonly value: string;
  /**Флаг сотрудника*/
  public readonly employFlag: number;
  /**Флаг напряжения*/
  public readonly voltageFlag: number;
  /**Флаг группы*/
  public readonly groupFlag: number;
  /**Короткая ссылка */
  public link : string = '';
  /**Текст подсказки */
  public tips : string = '';

  private objAnswers : Answer[];

  constructor(index: number, value: string, employFlag: number, voltageFlag: number, groupFlag: number) {
   super(index);
   this.value = value;
   this.employFlag = employFlag;
   this.voltageFlag = voltageFlag;
   this.groupFlag = groupFlag;
   this.objAnswers = [];
  }
  /**Метод возвращает весь массив ответов */
  public GetAnswers() : Answer[] {
   return this.objAnswers;
  }
  /**Метод добавляет ответ */
  public AddAnswer(answer : Answer) : void {
   this.objAnswers.push(answer);
  }
  /**Размер массива ответов */
  get Size() {
   return this.objAnswers.length;
  }
  /**Метод преобразует Json в Question */
  static FromJson(copy:Question) : Question {
   let newQuestion: Question = new Question(copy.index, copy.value, copy.employFlag, copy.voltageFlag, copy.groupFlag);
   newQuestion.link = copy.link;
   newQuestion.tips = copy.tips;

   copy.objAnswers.forEach(a => {
    const newAnswer: Answer = Answer.FromJson(a);
    newQuestion.objAnswers.push(newAnswer);
   });
   
   return newQuestion;
  }

}