import { useEffect, useRef, useState } from "react";
import Question from "../Classes/Question";
import IContainer from "./Container";
import './question.css';
import Answer from "./Answer";
import AnswerOperation from "../Enums/AnswerOperation";
import { isMobile } from "react-device-detect";

/**
 * Метод расчитывает оптимальный размер шрифта
 * @param length текущая длина текста
 * @param expectedLength ожидаемая длина текста
 */
function CalculateSize(length:number, expectedLength:number, baseSize:number, minSize:number) : number {
 const factor = 1 - Math.min(1 / expectedLength * length, 1);
 return Math.ceil(Math.max(factor * (baseSize - minSize) + minSize, minSize));
}

/**Настройки формы вопроса */
type QuestionProps = {
 /**Данные вопроса */
 question:Question;
 /**Контейнер данных */
 container?:IContainer | null;
 /**Событие выбора ответа */
 OnAnswerSelected(index:number): void;
}

/**Форма вопроса */
const QuestionForm = ({question, container = null, OnAnswerSelected} : QuestionProps) => {
 //Обработка размера шрифта при инициализации
 const labelRef = useRef<HTMLParagraphElement>(null);
 useEffect(() => {
  if (labelRef.current) {
   let fontSize = isMobile ? 36 : 54;

   const defLength = 120;
   const length = question.value.length;
   fontSize = CalculateSize(length, defLength, fontSize, isMobile ? 16 : 24);

   labelRef.current.style.fontSize = `${fontSize}px`;
  }
 }, []);

 //Обработка ответов
 //По умолчанию ответ не выбран значение -1
 const [answerIndex, setAnswerIndex] = useState<number>((container ? container.selectedAnswer : -1));
 const AnswerSelectionHandle = (index:number) => {
  if (answerIndex != index && index >= 0 && index < question.GetAnswers().length) {
   setAnswerIndex(index);
   OnAnswerSelected(index);
  }
 }

 return(
  <div className="content" style={isMobile ? {} : {minHeight:'500px', maxHeight:'100%', marginBottom:'1em'}}>
   {/*Блок заголовка вопроса>*/}
   <div className="top-container">
    <div className="top-box">
     <p ref={labelRef} className="question-label">{question.value}</p>
    </div>
   </div>
   
   {/*Блок ответов*/}
   <div className="answer-container" style={isMobile ? {} : {minHeight:'500px', maxHeight:'100%', height:'100%'}}>
    <div className="answer-box" style={isMobile ? {} : {overflow:'auto'}}>
     <ul className="answers">
      {question.GetAnswers().map((answer, index) => {

       let state:AnswerOperation = AnswerOperation.Idle;
       let isCorrect = false;

       if (container) {
        if (container.IsComplete()) {
         //Если вопрос завершен то отобразить правильно вариантов ответа
         if (answer.isCorrect) {
          state = AnswerOperation.Green;
          isCorrect = answerIndex == index;
         } else if (answerIndex == index) {
          state = AnswerOperation.Red;
         } else {
          state = AnswerOperation.Idle;
         }
        } else {
         //Если вопрос не завершен то выделить или снять выделение с ответа
         state = (answerIndex == index) ? AnswerOperation.Selected : AnswerOperation.Active;
        }
       }

       return (<Answer key={index} 
        value={answer.value}
        state={state}
        isCorrect={isCorrect}
        OnSelected={() => AnswerSelectionHandle(index)} />)
      })}
     </ul>
    </div>
   </div>
  </div>
 )
}

export default QuestionForm;