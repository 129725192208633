import { useEffect, useState } from "react";
import Theme from "../Classes/Theme";
import Question from "../Classes/Question";
import SortQuestions from "./Sorting";
import PortraitWork from "../Components/PortraitWork";

/**Форма данных для работы */
type WorkProps = {
 /**Флаг тестирования или экзамена */
 isExaming: boolean;
}

const Working = ({ isExaming }: WorkProps) => {
 //Загрузка данных User
 const userKey: string = 'User';
 const data: User = JSON.parse(sessionStorage.getItem(userKey) as string) as User;
 const [user, _] = useState<User>(data);

 //Данные вопросов
 const [questions, setQuestions] = useState<Question[]>([]);

 useEffect(() => {
  //Загрузка данных тем
  const themesKey = 'Themes';
  const restoredData = JSON.parse(sessionStorage.getItem(themesKey) as string);
  if (Array.isArray(restoredData)) {
   //Восстановление данных
   let restoredThemes: Theme[] = [];
   try {
    for (const copyTheme of restoredData) {
     const newTheme: Theme = Theme.FromJson(copyTheme as Theme);
     if (user.ThemeName == 'Все темы') restoredThemes.push(newTheme);
     else if (user.ThemeName == newTheme.value) {
      restoredThemes.push(newTheme);
      break;
     }
    }
   } catch(error) {
    console.log(`Не удалось восстановить данные тем ${error}`);
   } finally {
    //Обработка тем
    if (restoredThemes.length > 0) {

     const questionsLimit = (isExaming) ? user.questionsLimit : 0;

     const collectedQuestions: Question[] = SortQuestions(
      restoredThemes, 
      1 << user.EmployValue,
      1 << user.GroupValue,
      1 << user.VoltageValue,
      questionsLimit
      );

     if (collectedQuestions.length > 0) setQuestions(collectedQuestions);
    } else throw new Error('Не было найдено подходящих тем');
   }
  }
 }, [])

 return (
  <div>
   {questions.length > 0 ? <PortraitWork user={user} questions={questions} isExaming={isExaming}></PortraitWork> : <div></div>}
  </div>
 )
}

export default Working;