import './App.css';
import { Route, Routes } from 'react-router-dom';
import Pages from './Enums/Pages';
import Main from './Pages/Main';
import './Styles/styles.css';
import Working from './Pages/Working';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LoadForm from './Components/LoadingForm';

function App() {
 //Подключение service worker
 const [isInstalled, setIsInstalled] = useState<boolean>(false);

 //Проверка на подключение к сети
 const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

 //Проверка ну существование serivce-worker
 const hasService = 'serviceWorker' in navigator;

 //Установка service-worker
 const TryInstallWorker = () => {
  if (hasService) {
   if (isInstalled == false) {
    console.log('Запуск установки service-worker');
    navigator.serviceWorker.register('./service-worker.js').then((resp) => {
     setIsInstalled(true);
     console.log(`service-worker установлен для ${resp.scope}`);
    }, (err) => {console.log(err); setIsInstalled(true);}).catch((e) => {console.log(e); setIsInstalled(true);});
   }
  } else {
   //Если сертификата нет - то service-worker не запустисться
   //Просто запустить работу сайта
   if (isInstalled == false) setIsInstalled(true);
  }
 }

 useEffect(() => {
  if (isMobile) {
   /**
    ???????????????????
    Может на телефонах не успевает произойти загрузка документа до установки service-worker
    а на компах загрузка моментальная поэтому и обращение к кэшу моментально происходит?
    */
   if (document.readyState === 'complete') TryInstallWorker();
   else window.addEventListener('load', TryInstallWorker);
  } else TryInstallWorker();

  //Подписка от события изменения режима сети
  window.addEventListener('online', () => setIsOnline(true));
  window.addEventListener('offline', () => setIsOnline(false));

  return () => {
   //Отписка от события изменения режима сети
   window.removeEventListener('online', () => setIsOnline(true));
   window.removeEventListener('offline', () => setIsOnline(false));
  }

 }, [])

 return (
  isInstalled ? (
    <div className="App">
     <Routes>
      <Route path='*' element={<Main />} />
      <Route path={Pages.Testing} element={<Working isExaming={false} />} />
      <Route path={Pages.Exam} element={<Working isExaming={true} />} />
     </Routes>
    </div>
  ) : (
   { isMobile } ? (<LoadForm />) : (null)
  )
 );
}

export default App;
